import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Login from "./Components/Login";
import Layout from "./Components/Layout";
import Signup from "./Components/Signup";
import ForgotPassword from "./Components/ForgotPassword";
import { Provider } from "react-redux";
import store from "./redux/store";
import SettingPermission from "./Components/SettingPermission";
import User from "./Components/User";
import Country from "./Components/Country";
import HashTag from "./Components/Hashtag";
import Contacts from "./Components/Contacts";
import UserDetail from "./Components/UserDetail";
import HashtagDetail from "./Components/HashtagDetail";
import ContactDetail from "./Components/ContactDetail";
import Setting from "./Components/Setting";
import GeneralSetting from "./Components/GeneralSetting";
import UserHashtagContcatDetail from "./Components/UserHashContact";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Provider store={store}>
        <Login />
      </Provider>
    ),
  },
  {
    path: "/signup",
    element: <Signup />,
  },
  {
    path: "/forgotpassword",
    element: <ForgotPassword />,
  },
  {
    path: "/settings",
    element: (
      <Layout>
        <SettingPermission />
      </Layout>
    ),
  },
  {
    path: "/hashtag",
    element: (
      <Layout>
        <HashTag />
      </Layout>
    ),
  },
  {
    path: "/hashtag/:id",
    element: (
      <Layout>
        <HashtagDetail />
      </Layout>
    ),
  },
  {
    path: "/contacts",
    element: (
      <Layout>
        <Contacts />
      </Layout>
    ),
  },
  {
    path: "/contacts/:id",
    element: (
      <Layout>
        <ContactDetail />
      </Layout>
    ),
  },
  {
    path: "/country",
    element: (
      <Layout>
        <Country />
      </Layout>
    ),
  },
  {
    path: "/user",
    element: (
      <Layout>
        <User />
      </Layout>
    ),
  },
  {
    path: "/user/:id",
    element: (
      <Layout>
        <UserDetail />
      </Layout>
    ),
  },
  {
    path: "/user/hashtag/:id/:user",
    element: (
      <Layout>
        <UserHashtagContcatDetail />
      </Layout>
    ),
  },
  {
    path: "/setting",
    element: (
      <Layout>
        <Setting />
      </Layout>
    ),
  },
  {
    path: "/generalsetting",
    element: (
      <Layout>
        <GeneralSetting />
      </Layout>
    ),
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
