import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user_id: 1,
  contact_id: 1,
  hashtag_id: 1,
};

const idSlice = createSlice({
  name: "page",
  initialState,
  reducers: {
    setUserPageNum: (state, action) => {
      state.user_id = action.payload;
    },
    setContactPageNum: (state, action) => {
      state.contact_id = action.payload;
    },
    setHashtagPageNum: (state, action) => {
      state.hashtag_id = action.payload;
    },
  },
});

export const { setUserPageNum, setContactPageNum, setHashtagPageNum } =
  idSlice.actions;

export default idSlice.reducer;
