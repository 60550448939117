import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./authSlice";
import idReducer from "./idSlice";

const store = configureStore({
  reducer: {
    auth: authReducer,
    page: idReducer,
  },
});

export const RootState = store.getState;
export const AppDispatch = store.dispatch;

export default store;
