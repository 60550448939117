import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getApi } from "./Helper/Axios";
import InitialsImage from "./Initialimage";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Table from "react-bootstrap/esm/Table";
import Gobackbtn from "./Gobackbtn";
export default function UserDetail() {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const fetchData = async () => {
    await getApi(`get-user-contacts/${id}`, true)
      .then((res) => {
        if (res.status === 200) {
          setData(res.data);
        }

        if (res.status === 401) {
          errorNotify(res.data);
        }
      })
      .catch((err) => {
        console.log("Fetching data errror", err);
      });
  };
  useEffect(() => {
    fetchData();
  }, []);
  const successNotify = (message) =>
    toast.success(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  const errorNotify = (message) =>
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  function formatDate(isoString) {
    try {
      // Parse the ISO date string into a Date object
      const date = new Date(isoString);

      // Check if the date is valid
      if (isNaN(date.getTime())) {
        throw new Error("Invalid date");
      }

      // Options for formatting the date
      const options = { day: "2-digit", month: "long", year: "numeric" };

      // Use Intl.DateTimeFormat to format the date
      return new Intl.DateTimeFormat("en-GB", options).format(date);
    } catch (error) {
      // console.error("Failed to format date:", error);
      return "";
    }
  }
  return (
    <div>
      <Gobackbtn />
      <div className="d-flex user-details gap-4 align-items-center mb-3">
        <div className="user-image col-md-4">
          {data.image === null ||
          data.image === "null" ||
          data.image === undefined ||
          data.image === "" ? (
            <InitialsImage
              fullName={
                data?.first_name === "" ||
                data?.first_name === undefined ||
                data?.first_name === null
                  ? "Guest User"
                  : data?.first_name
              }
            />
          ) : (
            <img
              src={`${process.env.REACT_APP_UPLOADS_URL}users/${data?.image}`}
              alt="imag"
            />
          )}
        </div>
        <div className="user-content col-md-8">
          {(data?.first_name === "" ||
            data?.first_name === null ||
            data?.first_name === undefined) &&
          (data?.last_name === "" ||
            data?.last_name === null ||
            data?.last_name === undefined) ? null : (
            <div className="d-flex mb-2">
              <div className="showWrap view">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path
                    fill="currentcolor"
                    d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z"
                  />
                </svg>
              </div>
              {data?.first_name === "" ||
              data?.first_name === null ||
              data?.first_name === undefined ? null : (
                <p> {data?.first_name}</p>
              )}
              {data?.last_name === "" ||
              data?.last_name === null ||
              data?.last_name === undefined ? null : (
                <p>{data?.last_name}</p>
              )}
            </div>
          )}
          {data?.email === "" ||
          data?.email === null ||
          data?.email === undefined ? null : (
            <div className="d-flex mb-2">
              <div className="showWrap view">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path
                    fill="currentcolor"
                    d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z"
                  />
                </svg>
              </div>
              <p> {data?.email}</p>
            </div>
          )}
          {data?.mobile_num === "" ||
          data?.mobile_num === null ||
          data?.mobile_num === undefined ? null : (
            <div className="d-flex mb-2">
              <div className="showWrap view">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path
                    fill="currentcolor"
                    d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z"
                  />
                </svg>
              </div>
              <p> {data?.country_code + "  " + data?.mobile_num}</p>
            </div>
          )}
        </div>
      </div>

      {(data?.city === "" || data?.city === null || data?.city === undefined) &&
      (data?.state === "" ||
        data?.state === null ||
        data?.state === undefined) &&
      (data?.pin_code === "" ||
        data?.pin_code === null ||
        data?.pin_code === undefined) ? null : (
        <div className="contact-details mb-3 col-md-6">
          <div className="d-flex mb-2 justify-content-between">
            {data?.city === "" ||
            data?.city === null ||
            data?.city === undefined ? null : (
              <p>
                <b>City : </b>
                {data?.city}
              </p>
            )}
            {data?.state === "" ||
            data?.state === null ||
            data?.state === undefined ? null : (
              <p>
                <b>State : </b>
                {data?.state}
              </p>
            )}
            {data?.pin_code === "" ||
            data?.pin_code === null ||
            data?.pin_code === undefined ? null : (
              <p>
                <b>Pincode : </b>
                {data?.pin_code}
              </p>
            )}
          </div>
        </div>
      )}
      <div className="contact-details mb-3 col-md-6">
        <div className="d-flex mb-2 justify-content-between">
          <p>
            <b>Create : </b>
            {formatDate(data?.createdAt)}
          </p>

          <p>
            <b>Update : </b>
            {formatDate(data?.updatedAt)}
          </p>
        </div>
      </div>
      <div className="contact-details mb-3 col-md-12">
        <Tabs
          defaultActiveKey="home"
          transition={false}
          id="noanim-tab-example"
          className="mb-3"
        >
          <Tab
            eventKey="home"
            title={`Contact (${
              data?.userContacts?.length === undefined
                ? 0
                : data?.userContacts?.length
            })`}
            style={{ overflowY: "auto", height: "400px" }}
          >
            <Table responsive="sm" className="userdetails">
              <thead>
                <tr>
                  <td style={{ width: 350 }}>Name</td>
                  <td style={{ width: 200 }}>Hashtag</td>
                  <td style={{ width: 100 }}>City</td>
                  <td style={{ width: 150 }}>Phone Number</td>
                  <td>Notes</td>
                </tr>
              </thead>
              <tbody>
                {data?.userContacts?.length !== 0 ? (
                  data?.userContacts?.map((item, index) => {
                    let mobileNumbers = [];
                    try {
                      mobileNumbers = JSON.parse(item.mobile_numbers);
                    } catch (error) {
                      console.error(
                        "Failed to parse mobile number JSON:",
                        error
                      );
                    }
                    return (
                      <tr key={index} className="align-middle">
                        <td className="d-flex align-items-center">
                          <div className="imageWrap marginright">
                            {item.image === null ||
                            item.image === undefined ||
                            item.image === "" ||
                            item.image === "null" ? (
                              <InitialsImage
                                fullName={
                                  item?.first_name === "" ||
                                  item?.first_name === undefined ||
                                  item?.first_name === null
                                    ? "Guest User"
                                    : item?.first_name
                                }
                              />
                            ) : (
                              <img
                                src={`${process.env.REACT_APP_UPLOADS_URL}contact/${item?.image}`}
                                alt="imag"
                              />
                            )}
                          </div>
                          <Link to={`/contacts/${item?.id}`}>
                            {item.first_name + " " + item.last_name}
                          </Link>
                        </td>
                        <td>{item?.hashtags?.hash_tags}</td>
                        <td>{item.city}</td>
                        <td>{mobileNumbers[0].number}</td>
                        <td>{item.notes}</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={5} className="text-center">
                      No Records Found
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Tab>
          <Tab
            eventKey="profile"
            title={`Hash Tag (${
              data?.userHashtag?.length === undefined
                ? 0
                : data?.userHashtag?.length
            })`}
            style={{ overflowY: "auto", height: "400px" }}
          >
            <div className="hashtagwrap">
              {data?.userHashtag?.map((item) => (
                <Link
                  to={`/user/hashtag/${item?.hash_tags}/${item?.user_id}`}               
                  // to={`/hashtag/${item?.hash_tags}`}
                  className="d-flex mb-2 contactwrap"
                  key={item.id}
                >
                  <div className="showWrap view marginright">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path
                        fill="currentcolor"
                        d="M181.3 32.4c17.4 2.9 29.2 19.4 26.3 36.8L197.8 128h95.1l11.5-69.3c2.9-17.4 19.4-29.2 36.8-26.3s29.2 19.4 26.3 36.8L357.8 128H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H347.1L325.8 320H384c17.7 0 32 14.3 32 32s-14.3 32-32 32H315.1l-11.5 69.3c-2.9 17.4-19.4 29.2-36.8 26.3s-29.2-19.4-26.3-36.8l9.8-58.7H155.1l-11.5 69.3c-2.9 17.4-19.4 29.2-36.8 26.3s-29.2-19.4-26.3-36.8L90.2 384H32c-17.7 0-32-14.3-32-32s14.3-32 32-32h68.9l21.3-128H64c-17.7 0-32-14.3-32-32s14.3-32 32-32h68.9l11.5-69.3c2.9-17.4 19.4-29.2 36.8-26.3zM187.1 192L165.8 320h95.1l21.3-128H187.1z"
                      />
                    </svg>
                  </div>
                  {item?.hash_tags}
                </Link>
              ))}
            </div>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}
