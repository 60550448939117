import { useNavigate } from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

export default function Gobackbtn() {
  const navigate = useNavigate();
  return (
    <OverlayTrigger
      placement="right"
      delay={{ show: 250, hide: 400 }}
      overlay={<Tooltip id="button-tooltip">Back</Tooltip>}
    >
      <button onClick={() => navigate(-1)} className="arwleft">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          className="left-arrow-svg profile-svgs profile-imgs"
        >
          <path
            d="M9.57 5.92969L3.5 11.9997L9.57 18.0697"
            stroke="#ACACAC"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M20.4999 12H3.66992"
            stroke="#ACACAC"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
    </OverlayTrigger>
  );
}
