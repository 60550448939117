import React, { useState } from "react";
import * as Yup from "yup";
import TableDisplay from "./TableDisplay";

export default function Setting() {
  const [title, setTitle] = useState([
    "id",
    "appNewPackageName",
    "appVersionCode",
    "appRedirectOtherAppStatus",
    "appUpdateAppDialogStatus",
    "appUpdateAppForceDialogStatus",
    "createdAt",
    "updatedAt",
  ]);
const toFloatingPoint = (value) => {
  if (typeof value === 'string' && /^\d+$/.test(value)) {
    return `${value}.0`;
  }
  return value;
};
  const formConfig = {
    fields: [
      {
        name: "appUpdateAppDialogStatus",
        label: "Normal Update",
        type: "switchs",
      },
      {
        name: "appUpdateAppForceDialogStatus",
        label: "Force Update",
        type: "switchs",
      },
      {
        name: "appVersionCode",
        label: "",
        type: "text",
        placeholder: "Version Code",
      },
      {
        name: "appNewPackageName",
        label: "Redirect Other App",
        type: "text",
        placeholder: "Enter Redirect Other App",
      },
      {
        name: "appRedirectOtherAppStatus",
        type: "switchs",
      },
    ],
    validationSchema: Yup.object().shape({
      // appNewPackageName: Yup.string().required("Package Name Required"),
        appVersionCode: Yup.string()
    .transform(toFloatingPoint)
    .matches(/^(?:[a-zA-Z0-9]+\.)+[a-zA-Z0-9]+$/, "Format must be alphanumeric segments separated by dots")
    .required("Version Code Required"),
    }),

    // validationSchema: Yup.object({
    //   appNewPackageName: Yup.string().required("Package Name Required"),
    //   appVersionCode: Yup.string()
    //     .matches(/^[0-9]+$/, "Only numbers are allowed")
    //     .required("Version Code Required"),
    //   appRedirectOtherAppStatus: false,
    //   appUpdateAppDialogStatus: false,
    //   appUpdateAppForceDialogStatus: false,
    // }),
  };

  const initialValues = {
    appNewPackageName: "",
    appVersionCode: "",
    appRedirectOtherAppStatus: 0,
    appUpdateAppDialogStatus: 0,
    appUpdateAppForceDialogStatus: 0,
  };
  return (
    <div>
      <TableDisplay
        category="Setting"
        tableurl="admin-get-setting"
        title={title}
        deleteurl="admin-delete-setting"
        // deleteallurl="admin-all-delete-country"
        addurl="add-setting"
        formConfig={formConfig}
        initialValues={initialValues}
        updateurl="get-edit-setting"
        updatteurl="admin-edit-setting"
        serachCategory="setting"
        statusurl="admin-status-setting"
        axiosHeader={false}
      />
    </div>
  );
}
