import React, { useState } from "react";
import * as Yup from "yup";
import TableDisplay from "./TableDisplay";

export default function Country() {
  const [title, setTitle] = useState([
    "flag",
    "name",
    "dial_code",
    "code",
    "status",
  ]);

  const formConfig = {
    fields: [
      {
        name: "name",
        label: "Name",
        type: "text",
        placeholder: "enter name",
      },
      {
        name: "dial_code",
        label: "Dial Code",
        type: "text",
        placeholder: "enter dial code",
      },
      {
        name: "code",
        label: "Code",
        type: "text",
        placeholder: "enter code",
      },
      {
        name: "flag",
        label: "Flag",
        type: "file",
      },
      {
        name: "status",
        label: "Status",
        type: "switch",
      },
    ],

    validationSchema: Yup.object({
      name: Yup.string().required("country name Required"),
      dial_code: Yup.string()
        .matches(/^[\d+]+$/, "Invalid dial code")
        .required("Dial code is required"),
      code: Yup.string()
        .matches(/^\D+$/, "Invalid code")
        .required("String is required"),
      flag: Yup.mixed().required("Please upload an image"),
    }),
  };

  const initialValues = {
    name: "",
    dial_code: "",
    code: "",
    flag: "",
    status: 0,
  };
  return (
    <div>
      <TableDisplay
        category="Country"
        tableurl="get-all-country"
        title={title}
        deleteurl="admin-delete-country"
        deleteallurl="admin-all-delete-country"
        addurl="add-country"
        formConfig={formConfig}
        initialValues={initialValues}
        updateurl="get-edit-country"
        updatteurl="admin-edit-country"
        serachCategory="country"
        statusurl="admin-status-country"
        axiosHeader={true}
      />
    </div>
  );
}
