import React, { useEffect, useRef, useState } from "react";
import Table from "react-bootstrap/Table";
import { getApi, postApi } from "./Helper/Axios";
import Pagination from "./Pagination";
import MyContext from "./MyContext";
import Highlighter from "react-highlight-words";
import InitialsImage from "./Initialimage";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import Popup from "./Popup";
import { useDispatch, useSelector } from "react-redux";
import {
  setContactPageNum,
  setHashtagPageNum,
  setUserPageNum,
} from "../redux/idSlice";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
export default function TableDisplay({
  tableurl,
  title,
  category,
  formConfig,
  initialValues,
  addurl,
  updatteurl,
  axiosHeader,
  updateurl,
  statusurl,
  deleteurl,
}) {
  const [userSortDirection, setUserSortDirection] = useState("DESC"); // Initial sorting direction for user counter
  const [contactSortDirection, setContactSortDirection] = useState("DESC"); // Initial sorting direction for contact counter
  const [direction, setDirection] = useState("");
  const [usercontact, setUserContact] = useState("");
  const [data, setData] = useState([]);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [updatedata, setUpdateData] = useState({});
  const [isupdate, setIsupdate] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [deleteid, setdeleteid] = useState(0);
  const handleDeleteClose = () => setShowDelete(false);
  const handleDeleteShow = () => setShowDelete(true);
  const [id, setId] = useState();
  const [contactTitle, setContactTitle] = useState([
    "image",
    "first_name",
    "city",
    "hashtag_id",
    "mobile_numbers",
    "notes",
    "archived_type",
  ]);
  const [userTitle, setUserTitle] = useState([
    "image",
    "id",
    "first_name",
    "email",
    "mobile_num",
    "country_code",
    "createdAt",
    "counter",
    "is_app",
    "is_web",
    "both",
    "is_deleted",
    "is_recover",
    "is_permanent_delete",
  ]);
  const [hasgtagTitle, setHashtagTitle] = useState([
    "hash_tags",
    // "user_id",
    "user counter",
    "contact counter",
  ]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [error, setError] = useState("");
  const [isChecked, setIsChecked] = useState(
    selectedItems?.length === data?.length ? true : false
  );
  const [count, setCount] = useState(0);

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedOptions([...selectedOptions, value]);
    } else {
      setSelectedOptions(selectedOptions.filter((option) => option !== value));
    }
  };
  const [url, setUrl] = useState(
    category === "Users"
      ? "/user/"
      : category === "Contacts"
      ? "/contacts/"
      : category === "Hashtags"
      ? "/hashtag/"
      : null
  );
  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  /********************** */
  const [shows, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  /****************************** */
  /*************Pagination */
  const pageuser = useSelector((state) => state.page.user_id);

  const pagecontact = useSelector((state) => state.page.contact_id);

  const pagehashtag = useSelector((state) => state.page.hashtag_id);

  const [totalPages, setTotalPages] = useState(1);

  const [currentPage, setCurrentPage] = useState(
    category === "Users"
      ? pageuser
      : category === "Contacts"
      ? pagecontact
      : category === "Hashtags"
      ? pagehashtag
      : 1
  );

  const [search, setSearch] = useState(null);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = async () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const dispatch = useDispatch();
  const handlePageChange = (pageNumber) => {
    setSelectedItems([]);
    setCurrentPage(pageNumber);
    switch (category) {
      case "Users":
        dispatch(setUserPageNum(pageNumber));
        break;
      case "Contacts":
        dispatch(setContactPageNum(pageNumber));
        break;
      case "Hashtags":
        dispatch(setHashtagPageNum(pageNumber));
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (isDropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownOpen]);
  /***************** */
  const fetchData = async () => {
    let url =
      `${tableurl}?pagecount=${currentPage}` +
      (search ? `&search=${search}` : "") +
      (direction ? `&sortt=${direction}&cat=${usercontact}` : "") +
      (selectedOptions ? `&fields=${selectedOptions}` : "");
    await getApi(url, true)
      .then((res) => {     
        if (res.status === 200) {
          setData(res?.data);
          setTotalPages(res.totalPages);
          setCount(res.count);
        }
        if (res.status === 401) {
          setError(res.data);
        }
      })
      .catch((err) => {
        console.log("Fetching data errror", err);
      });
  };
  useEffect(() => {
    fetchData();
  }, [
    currentPage,
    search,
    selectedItems,
    category,
    selectedOptions,
    direction,
  ]);

  const value = {
    title: category,
    isupdate: isupdate,
    handleClose: handleClose,
    isShow: shows,
    formConfig: formConfig,
    url: addurl,
    data: isupdate ? updatedata : initialValues,
    fetchData: fetchData,
    updatteurl: updatteurl,
    id: id,
    axiosHeader: axiosHeader,
  };
  function renderTableCells(titles, row) {
    let mobileNumbers;
    try {
      mobileNumbers = JSON?.parse(row["mobile_numbers"]);
    } catch (error) {
      // console.error("Failed to parse mobile number JSON:", error);
    }

    return titles.map((title, colIndex) => {
      let cellContent;

      if (title === "mobile_numbers") {
        cellContent = mobileNumbers
          ? highlightText(mobileNumbers[0]?.number, search)
          : "";
      } else if (title === "hashtag_id") {
        row.hashtags?.hash_tags === null &&
        row.hashtags?.hash_tags === undefined &&
        row.hashtags?.hash_tags === ""
          ? (cellContent = "")
          : (cellContent = highlightText(
              "# " + row.hashtags?.hash_tags,
              search
            ));
      } else if (title === "user counter") {
        cellContent = row?.userCount;
      } else if (title === "contact counter") {
        cellContent = highlightText(row?.contactCount, search);
      } else if (title === "country_code") {
        cellContent = highlightText(row?.userCountry?.name, search);
      } else if (title === "user_id" && category === "Contacts") {
        cellContent = highlightText(row.contactuser?.first_name, search);
      } else if (title === "user_id") {
        cellContent = highlightText(row.hashuser?.first_name, search);
      } else if (title === "first_name") {
        const fullName = `${row.first_name || ""} ${
          row.last_name || ""
        }`.trim();
        cellContent =<Link className="showWrap marginright view"
                                to={`${url}${row.id}`}
                              >{ highlightText(fullName, search)}</Link>
      }else if (title === "hash_tags") {
        const hash_tags = `${row.hash_tags || ""} ${
          row.last_name || ""
        }`.trim();
        cellContent =<Link className="showWrap marginright view"
                                to={`${url}${row.hash_tags.replace(/#/g, "")}`}
                              >{ highlightText(hash_tags, search)}</Link>
      } else if (title === "last_name") {
        cellContent = null;
      } else if (title === "createdAt" && category==="Setting") {
        const { formattedDate, formattedTime } = formatDateTime(row.createdAt);
        cellContent = formattedDate +" "+ formattedTime;
      } else if (title === "updatedAt") {
         const { formattedDate, formattedTime } = formatDateTime(row.updatedAt);
         cellContent = formattedDate + "  " + formattedTime;
      } else if (title === "createdAt") {
        const { formattedDate, formattedTime } = formatDateTime(row[title]);
        cellContent = formattedDate + "  " + formattedTime;
      } else if (title === "is_app") {
        cellContent = row.is_app;
      } else if (title === "is_web") {
        cellContent = row[title];
      } else if (title === "both") {
        cellContent = row[title];
      } else if (title === "is_deleted") {
        cellContent = row[title];
      } else if (title === "is_recover") {
        cellContent = row[title];
      } else if (title === "is_permanent_delete") {
        cellContent = row[title];
      } else if (title === "counter") {
        cellContent = row?.userContacts?.length + "/" + row?.userHashtag?.length;
      } else if (title === "image" && category === "Users") {
        cellContent = (
          <div className="imageWrap">
            {!row.image ? (
              <InitialsImage
                fullName={
                  !row.first_name || row.first_name.trim() === ""
                    ? "Guest User"
                    : row.first_name
                }
              />
            ) : (
              <img
                src={`${process.env.REACT_APP_UPLOADS_URL}users/${row.image}`}
                alt="imag"
              />
            )}
          </div>
        );
      } else if (title === "image" && category === "Contacts") {
        cellContent = (
          <div className="imageWrap">
            {!row.image ? (
              <InitialsImage
                fullName={
                  !row.first_name || row.first_name.trim() === ""
                    ? "Guest User"
                    : row.first_name
                }
              />
            ) : (
              <img
                src={`${process.env.REACT_APP_UPLOADS_URL}contact/${row.image}`}
                alt="imag"
              />
            )}
          </div>
        );
      } else if (title === "flag") {
        cellContent = (
          <div className="imageWrap">
            <img
              src={`${process.env.REACT_APP_UPLOADS_URL}flags/${row.flag}`}
              alt="imag"
            />
          </div>
        );
      } else if (
        title === "status" ||
        title === "appRedirectOtherAppStatus" ||
        title === "appUpdateAppDialogStatus" ||
        title === "appUpdateAppForceDialogStatus"
      ) {
        cellContent = (
          <label className="switch">
            <input
              type="checkbox"
              checked={!!row[title]}
              onChange={() => handleStatusChange(row[title] ? 0 : 1, row.id)}
            />
            <span className="slider round"></span>
          </label>
        );
      } else {
        cellContent = highlightText(row[title], search);
      }

      return (
        <td key={colIndex} className={`align-middle left-column ${title}`}>
          {cellContent}
        </td>
      );
    });
  }
  const highlightText = (text, search) => (
    <Highlighter
      highlightClassName="YourHighlightClass"
      searchWords={[search]}
      autoEscape={true}
      textToHighlight={text ? text.toString() : ""}
    />
  );
  const handleUpdate = async (id) => {
    await getApi(`${updateurl}/${id}`, true)
      .then((res) => {
        setUpdateData(res.data);
        setIsupdate(true);
        handleShow();
      })
      .catch((err) => console.log(err));
  };
  const successNotify = (message) =>
    toast.success(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    }); 
  const handleDelete = async (id) => {
    await getApi(`${deleteurl}/${id}`, true)
      .then((res) => {
        if (res.status === 200) {
          fetchData();
          successNotify(res.data.message);
          handleDeleteClose();
          // fetchData();
          if (data.length === 0) {
            setCurrentPage(currentPage - 1);
          }
          setCurrentPage(currentPage);
        }
      })
      .catch((err) => console.log(err));
  };
  const handleStatusChange = async (statuss, id) => {
    const newdata = {
      status: statuss,
    };

    if (category === "Country") {
      try {
        const res = await postApi(`${statusurl}/${id}`, newdata, true);
        if (res.status === 200) {
          successNotify(res.data.message);
          fetchData();
        }
      } catch (err) {
        console.error(err);
      }
    }
  };
  function formatDate(isoString) {
    try {
      const date = new Date(isoString);
      if (isNaN(date.getTime())) {
        throw new Error("Invalid date");
      }
      const options = { day: "2-digit", month: "long", year: "numeric" };
      return new Intl.DateTimeFormat("en-GB", options).format(date);
    } catch (error) {
      // console.error("Failed to format date:", error);
      return "";
    }
  }

  const toggleSortDirection = (column) => {
    if (column === "user counter") {
      setUserSortDirection(userSortDirection === "ASC" ? "DESC" : "ASC");
      setDirection(userSortDirection === "ASC" ? "DESC" : "ASC");
      setUserContact("userCount");
      // Add sorting logic for user counter here
    } else if (column === "contact counter") {
      setContactSortDirection(contactSortDirection === "ASC" ? "DESC" : "ASC");
      setDirection(contactSortDirection === "ASC" ? "DESC" : "ASC");
      setUserContact("contactCount");
      // Add sorting logic for contact counter here
    }
    // Add more conditions for other columns if needed
  };
 const formatDateTime = (dateString) => {
   const date = new Date(dateString);

   // Format the date part
   const day = date.getDate();
   const month = date.toLocaleString("default", { month: "long" });
   const year = date.getFullYear();
   const formattedDate = `${day} ${month} ${year}`;

   // Format the time part
   let hours = date.getHours();
   const minutes = date.getMinutes();
   const ampm = hours >= 12 ? "PM" : "AM";
   hours = hours % 12;
   hours = hours ? hours : 12; // the hour '0' should be '12'
   const formattedTime = `${hours}:${minutes.toString().padStart(2, "0")} ${ampm}`;

   return { formattedDate, formattedTime };
 };
  const formatOption = (option) => {
    return option
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };
  return (
    <MyContext.Provider value={value}>
      {error ? (
        <div className="alert-danger text-center alert">
          <p>{error}</p>
        </div>
      ) : (
        <div>
          <div className="d-flex justify-content-between align-items-center mb-3 userr">
            <div className="title w-60 d-flex justify-content-between align-middle align-items-center user-info">
              <h4 className="d-flex justify-content-between marginright align-items-center">
                {category}
              </h4>
              {count === 1 ? (
                <h6 className="m-0">( {count} Record )</h6>
              ) : count > 0 ? (
                <h6>( {count} Records )</h6>
              ) : null}
            </div>
            <div className="w-50 d-flex flex-row-reverse user-info flex-wrap">
              {category === "Users" ||
              category === "Contacts" ||
              category === "Hashtags" ? null : (
                <button
                  className="mybtn marginleft mb-2"
                  onClick={() => {
                    handleShow();
                    setIsupdate(false);
                  }}
                >
                  {category === "Setting" ? "New Update" : `add ${category}`}
                </button>
              )}
             {category === "Setting" ?null: <div className="multi-select-dropdown" ref={dropdownRef}>
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip id="button-tooltip">Filter Columns</Tooltip>
                  }
                >
                  <button onClick={handleDropdownToggle} className="my-select">
                    Filter
                  </button>
                </OverlayTrigger>
                {title && (
                  <div
                    className={`dropdown-menu ${
                      isDropdownOpen ? "true" : "false"
                    }`}
                  >
                    {title?.map((option) => (
                      <div key={option} className="dropdown-item">
                        <label>
                          <input
                            type="checkbox"
                            value={option}
                            checked={selectedOptions.includes(option)}
                            onChange={handleCheckboxChange}
                            className="marginright"
                          />
                          {formatOption(option)}
                        </label>
                      </div>
                    ))}
                  </div>
                )}
              </div>}
              <div className="search marginright">
                <input
                  className="search__input marginright popup__input"
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  type="text"
                  placeholder={`Search`}
                />
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path
                    fill="black"
                    d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"
                  />
                </svg>
              </div>
            </div>
          </div>
            <Table className={`mytable ${category === "Users" ? "users" : ""} ${category}`} responsive="sm">
            <thead>
              <tr>
                {selectedOptions.length > 0
                  ? selectedOptions
                      .filter(
                        (colTitle) =>
                          colTitle !== "id" || colTitle !== "last_name"
                      )
                      .map((colTitle, index) => (
                        <th className={colTitle} key={index}>
                          {colTitle.replace("_", " ")}
                        </th>
                      ))
                  : category === "Contacts"
                  ? contactTitle
                      .filter(
                        (colTitle) =>
                          colTitle !== "id" || colTitle !== "last_name"
                      )
                      .map((colTitle, index) => (
                        <th key={index} className={colTitle}>
                          {colTitle === "last_name"
                            ? ""
                            : colTitle === "hashtag_id"
                            ? "Hashtag"
                            : colTitle === "mobile_numbers"
                            ? "Phone Number"
                            : colTitle === "first_name"
                            ? "Name"
                            : colTitle}
                        </th>
                      ))
                  : category === "Hashtags"
                  ? hasgtagTitle
                      .filter(
                        (colTitle) =>
                          colTitle !== "id" || colTitle !== "last_name"
                      )
                      .map((colTitle, index) => (
                        <th
                          key={index}
                          className={`${colTitle}`}
                          onClick={() => toggleSortDirection(colTitle)}
                        >
                          {colTitle}
                          {colTitle === "user counter" &&
                            (userSortDirection === "ASC" ? (
                              <span className="sort-icon triangle-up"></span>
                            ) : (
                              <span className="sort-icon triangle-down"></span>
                            ))}
                          {colTitle === "contact counter" &&
                            (contactSortDirection === "ASC" ? (
                              <span className="sort-icon triangle-up"></span>
                            ) : (
                              <span className="sort-icon triangle-down"></span>
                            ))}
                        </th>
                      ))
                  : category === "Users"
                  ? userTitle
                      .filter(
                        (colTitle) =>
                          colTitle !== "id" && colTitle !== "last_name"
                      )
                      .map((colTitle, index) => (
                        <th
                          key={index}
                          title={
                            colTitle === "counter" ? "Contact/Hashtag" : ""
                          }
                          className={colTitle}
                        >
                          {colTitle === "user_id"
                            ? "User Name"
                            : colTitle === "mobile_num"
                            ? "Phone Number"
                            : colTitle === "first_name"
                            ? "Name"
                            : colTitle.replace("_", " ")}
                        </th>
                      ))
                  : title
                      .filter(
                        (colTitle) =>
                          colTitle !== "id" && colTitle !== "last_name"
                      )
                      .map((colTitle, index) => (
                        <th key={index} className={colTitle}>
                          {colTitle === "user_id"
                            ? "User Name"
                            : colTitle === "appNewPackageName"
                            ? "Redirect App"
                            : colTitle === "appVersionCode"
                            ? "Version"
                            : colTitle === "appRedirectOtherAppStatus"
                            ? "Redirect Status"
                            : colTitle === "appUpdateAppDialogStatus"
                            ? "Normal Update"
                            : colTitle === "appUpdateAppForceDialogStatus"
                            ? "Force Update"
                            : colTitle === "mobile_num"
                            ? "Phone Number"
                            : colTitle === "first_name"
                            ? "Name"
                            : colTitle === "createdAt"
                            ? "Create Date"
                            : colTitle === "updatedAt"
                            ? "Update Date"
                            : colTitle.replace("_", " ")}
                        </th>
                      ))}
                <th className="ml-auto"></th>
              </tr>
            </thead>
            <tbody>
              {data && data.length > 0 ? (
                data?.map((row, index) => (
                  <tr key={index}>
                    {selectedOptions.length > 0
                      ? renderTableCells(
                          selectedOptions.filter(
                            (colTitle) =>
                              colTitle !== "id" && colTitle !== "last_name"
                          ),
                          row
                        )
                      : category === "Contacts"
                      ? renderTableCells(
                          contactTitle.filter(
                            (colTitle) =>
                              colTitle !== "id" && colTitle !== "last_name"
                          ),
                          row
                        )
                      : category === "Hashtags"
                      ? renderTableCells(
                          hasgtagTitle.filter(
                            (colTitle) =>
                              colTitle !== "id" && colTitle !== "last_name"
                          ),
                          row
                        )
                      : category === "Users"
                      ? renderTableCells(
                          userTitle.filter(
                            (colTitle) =>
                              colTitle !== "id" && colTitle !== "last_name"
                          ),
                          row
                        )
                      : renderTableCells(
                          title.filter(
                            (colTitle) =>
                              colTitle !== "id" && colTitle !== "last_name"
                          ),
                          row
                        )}

                    {category === "Country" || category === "Setting" ? (
                      <td className="align-middle">
                        <div className="d-flex justify-content-end">
                          <OverlayTrigger
                            placement="left"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                              <Tooltip id="button-tooltip">Edit</Tooltip>
                            }
                          >
                            <button
                              className="showWrap marginright"
                              onClick={() => {
                                handleUpdate(row.id);
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                              >
                                <path
                                  fill="currentcolor"
                                  d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"
                                />
                              </svg>
                            </button>
                          </OverlayTrigger>
                          <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                              <Tooltip id="button-tooltip">Delete</Tooltip>
                            }
                          >
                            <button
                              className="showWrap"
                              onClick={() => {
                                handleDeleteShow();
                                setdeleteid(row.id);
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                              >
                                <path
                                  fill="currentcolor"
                                  d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"
                                />
                              </svg>
                            </button>
                          </OverlayTrigger>
                        </div>
                      </td>
                    ) : (
                      <td className="align-middle">
                        <div className="d-flex justify-content-end">
                          {category === "Hashtags" ? (
                            <OverlayTrigger
                              placement="left"
                              delay={{ show: 250, hide: 400 }}
                              overlay={
                                <Tooltip id="button-tooltip">View</Tooltip>
                              }
                            >
                              <Link
                                className="showWrap marginright view"
                                to={`${url}${row.hash_tags.replace(/#/g, "")}`}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 576 512"
                                >
                                  <path
                                    fill="currentcolor"
                                    d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"
                                  />
                                </svg>
                              </Link>
                            </OverlayTrigger>
                          ) : category === "Contacts" ? (
                            <OverlayTrigger
                              placement="left"
                              delay={{ show: 250, hide: 400 }}
                              overlay={
                                <Tooltip id="button-tooltip">View</Tooltip>
                              }
                            >
                              <Link
                                className="showWrap marginright view"
                                to={`${url}${row.id}`}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 576 512"
                                >
                                  <path
                                    fill="currentcolor"
                                    d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"
                                  />
                                </svg>
                              </Link>
                            </OverlayTrigger>
                          ) : (
                            <OverlayTrigger
                              placement="left"
                              delay={{ show: 250, hide: 400 }}
                              overlay={
                                <Tooltip id="button-tooltip">View</Tooltip>
                              }
                            >
                              <Link
                                className="showWrap marginright view"
                                to={`${url}${row.id}`}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 576 512"
                                >
                                  <path
                                    fill="currentcolor"
                                    d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"
                                  />
                                </svg>
                              </Link>
                            </OverlayTrigger>
                          )}
                        </div>
                      </td>
                    )}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="8" className="text-center">
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </Table>

          {error && visibleAlert ? (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          ) : null}
          {data?.length > 0 && totalPages > 1 ? (
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onNext={handleNextPage}
              onPrev={handlePrevPage}
              handlePageChange={handlePageChange}
            />
          ) : null}
          <Modal show={showDelete} onHide={handleDeleteClose}>
            <Modal.Header closeButton>
              <Modal.Title>Delete</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure you want to delete this {category}.
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                className="mybtn black"
                onClick={handleDeleteClose}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                className="mybtn"
                onClick={() => handleDelete(deleteid)}
              >
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
          {shows && <Popup />}
        </div>
      )}
    </MyContext.Provider>
  );
}
