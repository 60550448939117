import { useState } from "react";
import { ReactComponent as RightArrowSvg } from "../images/2.svg";
import { ReactComponent as RightArrow } from "../images/1.svg";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
export default function Pagination({
  currentPage,
  totalPages,
  onNext,
  onPrev,
  handlePageChange,
}) {
  // const [currentPage, setCurrentPage] = useState(1);
  const maxPagesToShow = 5; // Number of page numbers to show
  // const paginate = (pageNumber) => {
  //     setCurrentPage(pageNumber);
  // };
  const getPageNumbers = () => {
    const pageNumbers = [];

    if (totalPages <= maxPagesToShow) {
      // If total pages is less than or equal to maxPagesToShow, show all pages
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      // If total pages is greater than maxPagesToShow, show subset with ellipsis
      const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);
      let startPage = currentPage - halfMaxPagesToShow;
      let endPage = currentPage + halfMaxPagesToShow;

      if (startPage <= 0) {
        startPage = 1;
        endPage = maxPagesToShow;
      }

      if (endPage > totalPages) {
        endPage = totalPages;
        startPage = totalPages - maxPagesToShow + 1;
      }

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }

      if (startPage > 1) {
        pageNumbers.unshift("...");
      }
      if (endPage < totalPages) {
        pageNumbers.push("...");
      }
    }

    return pageNumbers;
  };

  return (
    <div>
      {/* Pagination controls */}
      <div className="d-flex justify-content-center">
        {/* Previous button */}
        <OverlayTrigger
          placement="right"
          delay={{ show: 250, hide: 400 }}
          overlay={<Tooltip id="button-tooltip">First</Tooltip>}
        >
          <button
            className="pagination__next leftarw"
            onClick={() => handlePageChange(1)}
            disabled={currentPage === 1}
          >
            <RightArrowSvg />
          </button>
        </OverlayTrigger>
        <OverlayTrigger
          placement="right"
          delay={{ show: 250, hide: 400 }}
          overlay={<Tooltip id="button-tooltip">Previous</Tooltip>}
        >
          <button
            className="pagination__next leftarw"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            <RightArrow />
          </button>
        </OverlayTrigger>

        {/* Page numbers */}
        {getPageNumbers().map((pageNumber, index) => (
          <button
            className={`pagination__item ${
              currentPage === pageNumber ? "active" : ""
            }`}
            key={index}
            onClick={() => {
              if (pageNumber !== "...") {
                handlePageChange(pageNumber);
              }
            }}
            disabled={pageNumber === "..."}
          >
            {pageNumber}
          </button>
        ))}
        {/* Next button */}
        <OverlayTrigger
          placement="right"
          delay={{ show: 250, hide: 400 }}
          overlay={<Tooltip id="button-tooltip">Next</Tooltip>}
        >
          <button
            className="pagination__next"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            <RightArrow />
          </button>
        </OverlayTrigger>
        <OverlayTrigger
          placement="right"
          delay={{ show: 250, hide: 400 }}
          overlay={<Tooltip id="button-tooltip">Last</Tooltip>}
        >
          <button
            className="pagination__next"
            onClick={() => handlePageChange(totalPages)}
            disabled={currentPage === totalPages}
          >
            <RightArrowSvg />
          </button>
        </OverlayTrigger>
      </div>
    </div>
  );
}
