import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginUser } from "../redux/authSlice";
import Lottie from "lottie-react";
import loginImage from "../images/Animation - 1714535446652.json";
import { FiEye, FiEyeOff } from "react-icons/fi";
import logo from "../images/Frame 51871.png";
import { getApi } from "./Helper/Axios";
function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch(); // Initialize useDispatch hook
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState();
  const [emailStatus, setEmailStatus] = useState(false);
  const [formdata, setFormdata] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState("");
  useEffect(() => {
    let timer;
    if (error) {
      timer = setTimeout(() => {
        setError("");
      }, 3000); // 3000 milliseconds = 3 seconds
    }

    return () => {
      clearTimeout(timer);
    };
  }, [error]);
  const formik = useFormik({
    initialValues: formdata,
    validationSchema: Yup.object({
      email: Yup.string().required("email Required"),
      password: Yup.string().required("password Required"),
    }),
    onSubmit: async (values) => {
      await axios
        .post(`${process.env.REACT_APP_BASE_URL}check-admin`, values)
        .then((response) => {
          if (response.status === 200) {
            localStorage.setItem(
              `${process.env.REACT_APP_AUTH_KEY}`,
              response.data.data.token
            );
            dispatch(loginUser(response.data.data.user));
            formik.resetForm();
            navigate("/user");
          }
        })
        .catch((err) => {
          setError(err.response.data.message);
        });
    },
  });

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const checkEmail = async (e) => {
    setEmailStatus(true);
    await getApi(`admin-checkemail?email=${e}`)
      .then((response) => {
        if (response.status === 200) {
          setEmailStatus("Success");
        }
      })
      .catch((err) => {
        setError(err.response.data.message);
      });
  };
  return (
    <div className="container-login">
      <section
        className="login-section d-flex  
        align-items-center  
        justify-content-between  
        min-vh-100"
      >
        <div className="loginformWrap col-3">
          <div className="loginlogowrap d-flex justify-content-center  align-items-center mb-5">
            <div className="logo-wrap1 marginright">
              <svg
                width="50"
                height="50"
                viewBox="0 0 39 39"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="19.5" cy="19.5" r="19.5" fill="#386BF6"></circle>
                <path
                  opacity="0.4"
                  d="M29.9049 30.286C29.9049 30.6039 29.6551 30.8537 29.3373 30.8537H9.83326C9.51539 30.8537 9.26562 30.6039 9.26562 30.286C9.26562 25.586 13.8975 21.7715 19.5853 21.7715C20.7546 21.7715 21.8899 21.9304 22.9343 22.2369C22.2645 23.0316 21.8558 24.0647 21.8558 25.1773C21.8558 26.0288 22.0942 26.8348 22.5143 27.516C22.7413 27.902 23.0365 28.2539 23.3771 28.549C24.1718 29.2756 25.2276 29.7184 26.3969 29.7184C27.6684 29.7184 28.815 29.1962 29.6324 28.3561C29.8141 28.9691 29.9049 29.6162 29.9049 30.286Z"
                  fill="white"
                ></path>
                <path
                  d="M29.8113 22.1662C28.9939 21.2353 27.7792 20.6562 26.4395 20.6562C25.168 20.6562 24.0101 21.1785 23.1813 22.03C22.3866 22.8474 21.8984 23.9712 21.8984 25.1973C21.8984 26.0488 22.1368 26.8548 22.5569 27.536C22.7839 27.922 23.0791 28.2739 23.4197 28.5691C24.2144 29.2957 25.2702 29.7384 26.4395 29.7384C28.097 29.7384 29.5388 28.8529 30.3222 27.536C30.5606 27.15 30.7422 26.7073 30.8444 26.2531C30.9352 25.9126 30.9806 25.5606 30.9806 25.1973C30.9806 24.0394 30.5379 22.9722 29.8113 22.1662Z"
                  fill="white"
                ></path>
                <path
                  d="M24.6641 24.4893H28.1318"
                  stroke="#386BF6"
                  strokeWidth="0.6"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M24.6641 25.9756H28.1318"
                  stroke="#386BF6"
                  strokeWidth="0.6"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M26.0767 23.2158L25.0859 27.179"
                  stroke="#386BF6"
                  strokeWidth="0.6"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M27.7095 23.2158L26.7188 27.179"
                  stroke="#386BF6"
                  strokeWidth="0.6"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M19.5826 19.5002C22.7176 19.5002 25.259 16.9588 25.259 13.8238C25.259 10.6889 22.7176 8.14746 19.5826 8.14746C16.4476 8.14746 13.9062 10.6889 13.9062 13.8238C13.9062 16.9588 16.4476 19.5002 19.5826 19.5002Z"
                  fill="white"
                ></path>
              </svg>
            </div>
            <div className="logo-content">
              <h4>Hash Contacts</h4>
            </div>
          </div>
          <div className="login-title">
            <h4>Login</h4>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="login-feild-wrap">
              <div className="myinput">
                <input
                  type="text"
                  placeholder="Username"
                  name="email"
                  value={formik.values.email}
                  onChange={(e) => {
                    formik.setFieldValue("email", e.target.value);
                    setEmail(e.target.value);
                    checkEmail(e.target.value);
                  }}
                  className={formik.errors.email ? "errorinput" : ""}
                />
                {/* className={`mytable ${(category === "Users" ? 'users' : '')}`} */}
                {emailStatus && email !== "" ? (
                  <div
                    className={`svgwrap ${
                      emailStatus === "Success" ? "green" : ""
                    }`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path
                        fill="currentcolor"
                        d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                      />
                    </svg>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="error">
              {formik.touched.email && formik.errors.email ? (
                <p>{formik.errors.email}</p>
              ) : null}
            </div>
            <div className="login-feild-wrap">
              <div className="myinput">
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  className={formik.errors.password ? "errorinput" : ""}
                />
                <span className="eye" onClick={togglePasswordVisibility}>
                  {showPassword ? <FiEyeOff /> : <FiEye />}
                </span>
              </div>
              {/* <div className='d-flex flex-row-reverse mb-4 colr'>
                            <button type='button' className='forget-btn' onClick={handleForgetPassword}>Forget Password</button>
                        </div> */}
            </div>
            <div className="error">
              {formik.touched.password && formik.errors.password ? (
                <p>{formik.errors.password}</p>
              ) : null}
            </div>
            <div className="button-login">
              <button type="submit">Login</button>
            </div>
            {/* <div className='d-flex flex-row-reverse colr'>
                        <Link to='/signup'>Create Account</Link>
                    </div> */}
            <div className="error mt-2">
              {error && error ? <p>{error}</p> : null}
            </div>
          </form>
        </div>
        <div className="logincontainerwrap col-8 position-relative">
          <Lottie animationData={loginImage} loop={true} />
        </div>
      </section>
    </div>
  );
}

export default Login;
