import React, { useContext, useEffect, useMemo, useState } from "react";
import { getApi, postApi } from "./Helper/Axios";
import { useFormik } from "formik";
import MyContext from "./MyContext";
import { toast } from "react-toastify";

export default function DynamicForm() {
  const [isLoading, setIsLoading] = useState(false);
  const context = useContext(MyContext);
  const [file, setFile] = useState();
  const [selectedFiles, setSelectedFiles] = useState();  
  const id = context.data && context.data.id;
  const successNotify = (message) =>
    toast.success(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  const errorNotify = (message) =>
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  let [uploadMultImg, setUploadMultImg] = useState();
  const formik = useFormik({
    initialValues: context.data,
    validationSchema: context.formConfig.validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      let uurl = context.isupdate ? `${context.updatteurl}/${id}` : context.url;

      setIsLoading(true);
      await postApi(uurl, values, true, context.axiosHeader)
        .then((res) => {
          if (res.status === 200) {
            successNotify(res.message);
            formik.resetForm();
            context.fetchData();
            context.handleClose();
          }
          if (res.status === 422) {
            errorNotify(res.data.errors[0].message);
          }
        })
        .catch((err) => {
          errorNotify(err.data);
        });
    },
  });
  //  appRedirectOtherAppStatus
  //  appUpdateAppDialogStatus
  //  appUpdateAppForceDialogStatus
  const handlestatus = (name, status) => {
    if (name === "appUpdateAppForceDialogStatus") {
      formik.setFieldValue(name, status);
      formik.setFieldValue("appUpdateAppDialogStatus", 0);
      formik.setFieldValue("appRedirectOtherAppStatus", 0);
    } else if (name === "appUpdateAppDialogStatus") {
      formik.setFieldValue(name, status);
      formik.setFieldValue("appUpdateAppForceDialogStatus", 0);
      formik.setFieldValue("appRedirectOtherAppStatus", 0);
    } else if (name === "appRedirectOtherAppStatus") {
      formik.setFieldValue(name, status);
      formik.setFieldValue("appUpdateAppDialogStatus", 0);
      formik.setFieldValue("appUpdateAppForceDialogStatus", 0);
    }
  };
  return (
    <form
      onSubmit={formik.handleSubmit}
      className={context.title === "Setting" ? "settingform" : ""}
    >
      {context.formConfig.fields.map((field) => (
        <div key={field.name} className={field.name}>
          <label className="popup__label">{field.label}</label>
          {/* Render different input types based on field.type */}
          {field.type === "select" ? (
            // Render select dropdown
            <div className="selectWrap mb-3">
              <select
                name={field.name}
                onChange={formik.handleChange}
                className={
                  "my-select " + (formik.errors[field.name] ? "errorinput" : "")
                }
              >
                <option value="">Select {field.label}</option>
                {field.options &&
                  field.options.map((option) => (
                    <option
                      key={option.id}
                      value={option.id}
                      selected={option.id === formik.values[field.name]}
                    >
                      {option.name}
                    </option>
                  ))}
              </select>
            </div>
          ) : field.type === "file" ? (
            <div className="imageclass mb-3 d-flex">
              <div>
                <div className="imageWrapcustum marginright">
                  <input
                    type="file"
                    name={field.name}
                    onChange={(e) => {
                      formik.setFieldValue(field.name, e.target.files[0]);
                      setFile(URL.createObjectURL(e.target.files[0]));
                    }}
                  />
                </div>
                <div className="error mt-2">
                  {formik.touched[field.name] && formik.errors[field.name] ? (
                    <p> {formik.errors[field.name]}</p>
                  ) : null}
                </div>
              </div>
              {/* context.data */}

              {file ? (
                <div className="imageWrap profile  mt-2 mb-2">
                  <img src={file} alt="image12" />
                </div>
              ) : context.data.flag ? (
                <div className="imageWrap profile mt-2 mb-2">
                  <img
                    className="img-thumbnail"
                    src={`${process.env.REACT_APP_UPLOADS_URL}flags/${formik.values.flag}`}
                    alt={formik.values.flag}
                  />
                </div>
              ) : context.data.logo ? (
                <div className="imageWrap profile mt-2 mb-2">
                  <img
                    className="img-thumbnail"
                    src={`${process.env.REACT_APP_UPLOADS_URL}logos/${formik.values.logo}`}
                    alt={formik.values.logo}
                  />
                </div>
              ) : context.data.image ? (
                <div className="imageWrap profile mt-2 mb-2">
                  <img
                    className="img-thumbnail"
                    src={`${process.env.REACT_APP_UPLOADS_URL}images/${formik.values.image}`}
                    alt={formik.values.flag}
                  />
                </div>
              ) : null}
            </div>
          ) : field.type === "switch" ? (
            <label className="switch">
              <input
                type="checkbox"
                name={field.name}
                checked={formik.values[field.name]}
                // checked={formik.values.appRedirectOtherAppStatus}
                // value={formik.values[field.name]}
                onChange={(e) => {
                  formik.setFieldValue(
                    field.name,
                    formik.values[field.name] === 1 ? 0 : 1
                  );
                }}
              />
              <span className="slider round"></span>
            </label>
          ) : field.type === "switchs" ? (
            <label className="switch">
              <input
                type="checkbox"
                name={field.name}
                checked={formik.values[field.name]}
                // checked={formik.values.appRedirectOtherAppStatus}
                // value={formik.values[field.name]}
                onChange={(e) =>
                  handlestatus(
                    field.name,
                    formik.values[field.name] === 1 ? 0 : 1
                  )
                }
              />
              <span className="slider round"></span>
            </label>
          ) : field.type === "textarea" ? (
            <div className="form-input">
              <textarea
                name={field.name}
                value={formik.values[field.name]}
                onChange={formik.handleChange}
                className={
                  "popup__input " +
                  (formik.errors[field.name] ? "errorinput" : "")
                }
              />
            </div>
          ) : field.type === "files" ? (
            <div className="imageclass mb-3 d-flex">
              <div className="imageWrapcustum marginright">
                <input
                  type="file"
                  multiple
                  name={field.name}
                  onChange={(e) => {
                    setUploadMultImg(e.target.files);
                    setSelectedFiles(Array.from(e.target.files));
                  }}
                />
              </div>
              <div className="error">
                {formik.touched[field.name] && formik.errors[field.name] ? (
                  <p> {formik.errors[field.name]}</p>
                ) : null}
              </div>
              <div className="image-preview-container d-flex"></div>
            </div>
          ) : (
            // Render other input types (e.g., text, number, email)
            <div className="form-input">
              <input
                type={field.type}
                placeholder={field.placeholder}
                name={field.name}
                value={formik.values[field.name]}
                onChange={formik.handleChange}
                className={
                  "popup__input " +
                  (formik.errors[field.name] ? "errorinput" : "")
                }
              />
              <div className="error">
                {formik.touched[field.name] && formik.errors[field.name] ? (
                  <p> {formik.errors[field.name]}</p>
                ) : null}
              </div>
            </div>
          )}
        </div>
      ))}
      <div className="d-flex justify-content-center ">
        <button
          className="mybtn d-flex justify-content-center"
          type="submit"
          // disabled={isLoading}
        >
          <span className={"loader-1 marginright " + (isLoading ? "show" : "")}>
            {" "}
          </span>
          {context?.isupdate ? (context?.title === "Setting" ? "Save" :
          `Update ${context?.title}`) : (context?.title === "Setting" ? "Save" : `Add ${context?.title}`)}
          {/* {context.isupdate
            ? `update ${context.title}`
            : isLoading === true
            ? "Loading"
            : `add ${context.title}`} */}
        </button>
      </div>
    </form>
  );
}
