import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import React, { useContext } from "react";
import DynamicForm from "./DynamicForm";
import MyContext from "./MyContext";

export default function Popup() {
  const context = useContext(MyContext);
  return (
    <div>
      <Modal show={context?.isShow}>
        {/* <Modal > */}
        <Modal.Header className="d-flex justify-content-between">
          <Modal.Title>
            {context?.isupdate
    ? (context?.title === "Setting" ? "New Update" : `Update ${context?.title}`)
    : (context?.title === "Setting" ? "New Update" : `Add ${context?.title}`)}
          </Modal.Title>
          <Button
            className="showWrap rmvpopbtn"
            onClick={() => context.handleClose()}
          >
            {/* <Button className='showWrap'> */}
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
              <path
                fill="currentcolor"
                d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
              />
            </svg>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <DynamicForm />
        </Modal.Body>
      </Modal>
    </div>
  );
}
