import React, { useState } from "react";
import * as Yup from "yup";
import TableDisplay from "./TableDisplay";

export default function User() {
  const [title, setTitle] = useState([
    "image",
    "first_name",
    "email",
    "mobile_num",
    "country_code",
    "pin_code",
    "state",
    "city",
    "createdAt",
    "is_web",
    "is_app",
    "both",
    "is_deleted",
    "is_recover",
    "is_permanent_delete",
  ]);
  return (
    <div>
      <TableDisplay category="Users" tableurl="get-all-users" title={title} />
    </div>
  );
}
