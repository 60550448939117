import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { ReactComponent as LogoSvg } from "../../images/logo.svg";
import { ReactComponent as UserSvg } from "../../images/user.svg";
import { ReactComponent as CountrySvg } from "../../images/location.svg";
import { ReactComponent as SitesSvg } from "../../images/sites.svg";
import { ReactComponent as WallpapersSvg } from "../../images/wallpapers.svg";
import { ReactComponent as SettingSvg } from "../../images/settings.svg";
import { useDispatch } from "react-redux";
import { setPageNum } from "../../redux/idSlice";
export default function Sidebar() {
  // const user = useSelector((state) => state.auth.user);
  const location = useLocation();
  const path = location.pathname;
  // const dispatch = useDispatch();
  // useEffect(() => {
  //   handleGetUser();
  // }, []);

  // const handleGetUser = async () => {
  //   await getApi("admin-user", true)
  //     .then(({ data }) => {
  //       dispatch(loginUser(data));
  //     })
  //     .catch((err) => console.log(err));
  // };
  const dispatch = useDispatch();
  return (
    <>
      <div className="navbarclass">
        <label htmlFor="menu" className="icon">
          <div className="menu d-flex justify-content-between">
            <div className="logo-wrap">
              <LogoSvg />
              {/* <img src={logoImage} alt="logo" /> */}
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              viewBox="0 0 24 24"
            >
              <path d="M22 12H3" stroke="#11142d"></path>
              <g stroke="#808191">
                <path d="M22 4H13"></path>
                <path opacity=".301" d="M22 20H13"></path>
              </g>
              <path d="M7 7l-5 5 5 5" stroke="#11142d"></path>
            </svg>
          </div>
        </label>
        <nav>
          <div className="menu-title">
            <p>Admin tools</p>
          </div>
          <ul className="sidemenu">
            <li
              className={
                path === "/user" || path.startsWith("/user/") ? "active" : ""
              }
            >
              <NavLink
                aria-current="page"
                to="/user"
                // onClick={dispatch(setPageNum(1))}
              >
                <div className="svgwarp">
                  <UserSvg />
                </div>
                User
              </NavLink>
            </li>
            <li className={`${path}` === "/country" ? "active" : ""}>
              <NavLink aria-current="page" to="/country">
                <div className="svgwarp">
                  <CountrySvg />
                </div>
                Country
              </NavLink>
            </li>

            <li
              className={
                `${path}` === "/hashtag" || path.startsWith("/hashtag/")
                  ? "active"
                  : ""
              }
            >
              <NavLink
                aria-current="page"
                to="/hashtag"
                // onClick={dispatch(setPageNum(1))}
              >
                <div className="svgwarp">
                  <SitesSvg />
                </div>
                Hash Tag
              </NavLink>
            </li>

            <li
              className={
                `${path}` === "/contacts" || path.startsWith("/contacts/")
                  ? "active"
                  : ""
              }
            >
              <NavLink
                aria-current="page"
                to="/contacts"
                // onClick={dispatch(setPageNum(1))}
              >
                <div className="svgwarp">
                  <WallpapersSvg />
                </div>
                Contacts
              </NavLink>
            </li>
            <li
              className={
                `${path}` === "/setting" || `${path}` === "/generalsetting"
                  ? "active"
                  : ""
              }
            >
              <NavLink aria-current="page" to="/generalsetting">
                <div className="svgwarp">
                  <SettingSvg />
                </div>
                Settings
              </NavLink>
            </li>
            {/*   <li className={`${path}` === "/backup" ? "active" : ""}>
              <NavLink aria-current="page" to="/backup">
                <div className="svgwarp">
                  <SettingSvg />
                </div>
                Database Backup
              </NavLink>
            </li> */}
            {/* </>
            ) : null} */}
          </ul>
        </nav>
      </div>
    </>
  );
}
