import React, { useState } from "react";
import TableDisplay from "./TableDisplay";

export default function Contacts() {
  const [title, setTitle] = useState([
    "image",
    "user_id",
    "first_name",
    "last_name",
    "mobile_numbers",
    "hashtag_id",
    "city",
    "archived_type",
  ]);
  return (
    <div>
      <TableDisplay
        category="Contacts"
        tableurl="get-all-contact"
        title={title}
      />
    </div>
  );
}
