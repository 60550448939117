import React, { useEffect, useState } from "react";
import phoneIcon from "../images/phone.svg"; // Default phone icon
import mobileIcon from "../images/phone-.svg"; // Mobile phone icon
import homeIcon from "../images/home.svg"; // Home phone icon
import workIcon from "../images/phone.svg"; // Work phone icon

const MobileNumbers = ({ data }) => {
  let parsedData = [];

  // Check if data is an array of objects
  if (Array.isArray(data) && data.length > 0 && typeof data[0] === "object") {
    parsedData = data;
  } else {
    try {
      // Try parsing JSON if data is a string
      const parsedJson = JSON.parse(data);
      if (Array.isArray(parsedJson)) {
        // Check if parsed JSON is an array of objects
        if (parsedJson.length > 0 && typeof parsedJson[0] === "object") {
          parsedData = parsedJson;
        }
        parsedData = [JSON.parse(parsedJson)];
      }
    } catch (error) {
      // console.error("Failed to parse data:", error);
    }
  }
  const getIconByType = (type) => {
    switch (type.toLowerCase()) {
      case "mobile":
        return mobileIcon;
      case "work":
        return workIcon;
      case "home":
        return homeIcon;
      default:
        return workIcon; // Handle default icon or fallback logic here
    }
  };

  return (
    <div>
      {parsedData.map((num, index) => (
        <div key={index} className="contactdetails d-flex gap-2 mb-4">
          <div className="iconwrap">
            <img src={getIconByType(num.type)} alt={`${num.type} icon`} />
          </div>
          <div>
            <h6>{num.type.charAt(0)?.toUpperCase() + num.type.slice(1)}</h6>
            <p>{num.number}</p>
          </div>
        </div>
      ))}
    </div>
  );
};
export default MobileNumbers;
