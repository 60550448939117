import React, { useState } from "react";
import * as Yup from "yup";
import TableDisplay from "./TableDisplay";

export default function HashTag() {
  const [title, setTitle] = useState(["hash_tags", "user_id"]);
  const formConfig = {
    fields: [
      {
        name: "name",
        label: "Name",
        type: "text",
        placeholder: "enter name",
      },
      {
        name: "dial_code",
        label: "Dial Code",
        type: "text",
        placeholder: "enter dial code",
      },
      {
        name: "code",
        label: "Code",
        type: "text",
        placeholder: "enter code",
      },
      {
        name: "flag",
        label: "Flag",
        type: "file",
      },
      {
        name: "status",
        label: "Status",
        type: "switch",
      },
    ],
    validationSchema: Yup.object({
      name: Yup.string().required("name Required"),
      icon: Yup.mixed().required("Please upload an image"),
    }),
  };

  const initialValues = {
    name: "",
    icon: "",
    status: false,
  };
  return (
    <div>
      <TableDisplay
        category="Hashtags"
        tableurl="get-all-hashtag"
        title={title}
        // deleteurl="archive-search-engine"
        // deleteallurl="all-archive-search-engine"
        // addurl="add-search-engine"
        formConfig={formConfig}
        initialValues={initialValues}
        // updateurl="get-search-engine"
        // updatteurl="add-search-engine"
        // serachCategory="name"
        // statusurl="status-search-engine"
        axiosHeader={true}
      />
    </div>
  );
}
