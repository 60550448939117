import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { getApi } from "./Helper/Axios";
import Table from "react-bootstrap/esm/Table";
import InitialsImage from "./Initialimage";
import Gobackbtn from "./Gobackbtn";
export default function UserHashtagContcatDetail() {
    const { id } = useParams();
    const { user } = useParams();
    const [data, setData] = useState([]);   
    const fetchData = async () => {
        try {
            const res = await getApi(`get-hashtag-contact/${id}?user=${user}`, true);            
            if (res.status === 200) {                
                setData(res.data);
            } else if (res.status === 401) {
                // Handle 401 error
                // errorNotify(res.data);
            }
        } catch (err) {
            console.log("Fetching data error", err);
        }
    };  
    useEffect(() => {
        fetchData();        
    }, [id]);

    return (
        <div>
            <div className="d-flex gap-4">
                <Gobackbtn />
                <h5 className="">#{id} ({data?.length === undefined ? 0 : data?.length})</h5>
            </div>
            {/* <Tabs
                defaultActiveKey="profile"
                transition={false}
                id="noanim-tab-example"
                className="mb-3"
            >
               
                <Tab
                    eventKey="profile"
                    title={`Contacts (${data?.length === undefined ? 0 : data?.length})`}
                    style={{ overflowY: "auto", height: "650px" }}
                > */}
                    <Table>
                        <thead>
                            <tr>
                                <td style={{ width: 350 }}>Name</td>
                                <td style={{ width: 120 }}>City</td>
                                <td style={{ width: 150 }}>Phone Number</td>
                                <td>Notes</td>
                            </tr>
                        </thead>
                        <tbody>
                            {data.length === 0 ? (
                                <tr>
                                    <td colSpan="4" className="text-center">
                                        No Records Found
                                    </td>
                                </tr>
                            ) : (
                        data?.map((item) => {
                            const fullName = item.first_name || "Guest User";
                            const lastName = item.last_name || "";
                            const displayName = `${fullName} ${lastName}`.trim();

                            let mobileNumbers = [];

                            try {
                                mobileNumbers = JSON?.parse(item?.mobile_numbers);
                            } catch (error) {
                                console.error("Failed to parse mobile number JSON:", error);
                            }

                            let firstNumber = "";
                            if (mobileNumbers.length > 0) {
                                try {
                                    const firstNumberObject = JSON?.parse(mobileNumbers[0]);
                                    firstNumber = firstNumberObject?.number || "";
                                } catch (error) {
                                    console.error("Failed to parse first mobile number JSON:", error);
                                }
                            }

                            return (
                                <tr key={`${item.id}`}>
                                    <td className="d-flex">
                                        <Link to={`/contacts/${item.id}`} className="d-flex align-items-center">
                                            <div className="imageTable marginright">
                                                {item.image === null || item.image === "null" || item.image === undefined || item.image === "" ? (
                                                    <InitialsImage fullName={displayName} />
                                                ) : (
                                                    <img
                                                        src={`${process.env.REACT_APP_UPLOADS_URL}contact/${item.image}`}
                                                        alt="imag"
                                                    />
                                                )}
                                            </div>
                                            {displayName === "Guest User" ? "" : displayName}
                                        </Link>
                                    </td>
                                    <td>{item.city}</td>
                                    <td>{firstNumber}</td>
                                    <td>{item.notes}</td>
                                </tr>
                            )
                        }))
                            } 
                        </tbody>
                    </Table>
                {/* </Tab>
            </Tabs> */}
        </div>
    );
}
