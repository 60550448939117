// src/InitialsImage.js

import React, { useRef, useEffect, useState } from "react";

const InitialsImage = ({
  fullName,
  size = 200,
  gradientColors = ["#386BF6", "#6c5dd3"],
  textColor = "#fff",
}) => {
  const canvasRef = useRef(null);
  const [imageSrc, setImageSrc] = useState("");
  const [initials, setInitials] = useState("");
  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    // Set canvas dimensions
    canvas.width = size;
    canvas.height = size;

    // Create gradient
    const gradient = ctx.createLinearGradient(3, 0, size, size);
    gradientColors.forEach((color, index) => {
      gradient.addColorStop(index / (gradientColors.length - 1), color);
    });

    // Draw background
    ctx.fillStyle = gradient;
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // Draw text
    const initials = getInitials(fullName);
    ctx.fillStyle = textColor;
    ctx.font = `${size / 2}px Arial`;
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.fillText(initials, size / 2, size / 2);

    // Convert canvas to image and set it as the source of an img element
    const dataUrl = canvas.toDataURL();
    setInitials(initials);
    setImageSrc(dataUrl);
  }, [fullName, size, gradientColors, textColor]);

  const getInitials = (name) => {
    const names = name?.split(" ");
    const initials = names?.map((n) => n[0]?.toUpperCase()).join("");
    return initials?.substring(0, 2); // Take only the first two initials
  };

  return (
    <>
      <canvas ref={canvasRef} style={{ display: "none" }} />
      {/* {fullName} */}
      {imageSrc && <img src={imageSrc} alt={fullName} />}
    </>
  );
};

export default InitialsImage;
