import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { getApi } from "./Helper/Axios";
import InitialsImage from "./Initialimage";
import MobileNumbers from "./MobileNumbers";
import { ReactComponent as ArchiveSvg } from "../images/archive.svg";
import Gobackbtn from "./Gobackbtn";
export default function ContactDetail() {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const fetchData = async () => {
    await getApi(`get-contact-user/${id}`, true)
      .then((res) => {
        if (res.status === 200) {
          setData(res.data);
        }
        if (res.status === 401) {
          console.error(res.data);
        }
      })
      .catch((err) => {
        console.log("Fetching data errror", err);
      });
  };
  useEffect(() => {
    fetchData();
  }, []);
  function formatDate(isoString) {
    try {
      // Parse the ISO date string into a Date object
      const date = new Date(isoString);

      // Check if the date is valid
      if (isNaN(date.getTime())) {
        throw new Error("Invalid date");
      }

      // Options for formatting the date
      const options = { day: "2-digit", month: "long", year: "numeric" };

      // Use Intl.DateTimeFormat to format the date
      return new Intl.DateTimeFormat("en-GB", options).format(date);
    } catch (error) {
      // console.error("Failed to format date:", error);
      return "";
    }
  }
  return (
    <div>
      <Gobackbtn />
      <div className="d-flex contacts-details gap-4 align-items-center">
        <div className="user-image">
          {data.image === null ||
          data.image === "null" ||
          data.image === undefined ||
          data.image === "" ? (
            <InitialsImage
              fullName={
                data?.first_name === "" ||
                data?.first_name === undefined ||
                data?.first_name === null
                  ? "Guest User"
                  : data?.first_name
              }
            />
          ) : (
            <img
              src={`${process.env.REACT_APP_UPLOADS_URL}contact/${data?.image}`}
              alt="imag"
            />
          )}
        </div>
        <div className="user-content">
          {data?.first_name === "" ||
          data?.first_name === null ||
          data?.first_name === undefined ? null : (
            <div className="d-flex">
              <h4> {data?.first_name + " " + data?.last_name}</h4>
            </div>
          )}
          {data?.city === "" ||
          data?.city === null ||
          data?.city === undefined ? null : (
            <div className="d-flex">
              <p> {data?.city}</p>
            </div>
          )}
        </div>
        <div className="crate-details m-lg-auto">
          <p className="mt-4">
            <b>Create : </b>
            {formatDate(data?.createdAt)}
          </p>

          <p>
            <b>Update : </b>
            {formatDate(data?.updatedAt)}
          </p>
        </div>
      </div>

      <div className="divider"></div>
      <div className="d-flex flex-column flex-lg-row gap-4">
        <div className="d-flex flex-column w-lg-50 w-100 profile-info-sec">
          <h6 className="mb-3">Contact Details</h6>
          <MobileNumbers data={data?.mobile_numbers} />
        </div>
        <div className="extra-info w-lg-50 w-100 gap-4 d-flex flex-column ">
          <div className="d-flex justify-content-between profile-info-sec">
            <h6>Hashtag</h6>
            <Link to={`/hashtag/${data?.hashtags?.hash_tags}`}>
              <p>{data?.hashtags?.hash_tags}</p>
            </Link>
          </div>
          <div className="d-flex justify-content-between profile-info-sec">
            <h6>
              <ArchiveSvg className="marginright" />
              Archive
            </h6>
            <p>{data?.archived_type}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
