import React from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as SettingSvg } from "../images/refresh-2 (1).svg";
export default function GeneralSetting() {
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div className="title w-60 d-flex justify-content-between align-middle align-items-center">
          <h4 className="d-flex justify-content-between marginright align-items-center">
            Setting
          </h4>
        </div>
      </div>
      <div className="d-flex align-items-center gap-4">
        <Link to="/setting" className="seetingtools col-3">
        <SettingSvg className="marginright"/>  Version Update
        </Link>       
      </div>
    </div>
  );
}
